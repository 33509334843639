import React from 'react'
import { connect } from 'react-redux'
import { Box, Button, Heading, FormField, Text, TextInput } from 'grommet'
import { Alert } from 'grommet-icons'

import { changeEmail, resetPassword } from '../actions/user'
import AppLayout from '../components/app-layout'

const mapStateToProps = state => ({
  email: state.signup.email,
  ready: (
    state.signup.email
  ),
  loading: state.signup.loading,
  error: state.signup.error
})

const mapDispatchToProps = dispatch => ({
  onEmailChange: val => dispatch(changeEmail(val)),
  onPasswordReset: val => dispatch(resetPassword(val))
})

const reset = ({
  email,
  ready,
  error,
  onEmailChange,
  onPasswordReset
}) => (
  <AppLayout page="reset">
    <Box
      style={{ maxWidth: '48rem', marginLeft: 'auto', marginRight: 'auto' }}
      fill={true}
      pad='xlarge'
    >
      <Heading color='accent-2'>
        Reset Password
      </Heading>
      {
        error &&
        <Box
          background='status-error'
          align='center'
          direction='row'
          pad='small'
          gap='small'
          round='xsmall'
        >
          <Alert size='small' color='white' />
          <Text size='small' color='white'>{ error }</Text>
        </Box>
      }
      <Box
        gap='medium'
        pad={{ top: 'medium' }}
      >
        <FormField
          label='EMAIL'
        >
          <TextInput
            onChange={evt => onEmailChange(evt.target.value)}
            value={email}
          />
        </FormField>
      </Box>
      <Box
        direction='row-responsive'
        align='center'
        pad={{ top: 'xlarge' }}
        justify='center'
      >
        <Button
          style={{margin: '15px'}}
          onClick={ready ? () => onPasswordReset(email) : null}
          label={'Forgot Password'}
          color='accent-1'
          primary
        />
      </Box>
    </Box>
  </AppLayout>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reset)
